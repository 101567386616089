<template>
	<span
		class="quantity-picker"
		:style="computedStyles"
		:class="{ 'quantity-picker--cart-style' : isCartStyle }"
	>
		<button
			v-qa="`${dataQaPageType}-btn-decrease`"
			class="quantity-picker__control"
			:class="{ 'quantity-picker__control--rounded quantity-picker__control--bigger' : isCartStyle }"
			@click="$emit('decrease-quantity')"
		>
			-
		</button>
		<span
			v-qa="`${dataQaPageType}-text-qty`"
			class="quantity-picker__amount"
			:class="{ 'quantity-picker__amount--borderless' : isCartStyle }"
		>
			{{ quantity }}
		</span>
		<button
			v-qa="`${dataQaPageType}-btn-increaseq`"
			class="quantity-picker__control"
			:class="{ 'quantity-picker__control--rounded quantity-picker__control--bigger' : isCartStyle }"
			:disabled="!isStockAvailable"
			@click="$emit('increase-quantity')"
		>
			+
		</button>
	</span>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'QuantityPicker',
	props: {
		dataQaPageType: {
			type: String,
			default: '',
		},
		isStockAvailable: {
			type: Boolean,
			default: true,
		},
		quantity: {
			type: Number,
			required: true,
		},
		size: {
			type: Number,
			default: 48,
		},
		fontSize: {
			type: Number,
			default: 16,
		},
		isCartStyle: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		computedStyles() {
			return {
				'--size': `${this.size}px`,
				'--font-size': `${this.fontSize}px`,
			};
		},
	},
});
</script>
<style lang="scss" scoped>
$color-grey-light: #e0e0e0;

.quantity-picker {
	display: flex;
	height: var(--size);
	border: 1px solid $grey-400;

	&--cart-style {
		color: $grey-700;
		border-radius: 4px;
	}

	&__control {
		width: var(--size);
		font-size: 16px;
		cursor: pointer;

		&--bigger {
			font-size: 20px;
		}

		&--rounded {
			border-radius: 3px 0 0 3px;

			&:nth-of-type(2) {
				border-radius: 0 3px 3px 0;
			}
		}

		&:disabled {
			cursor: inherit;
			background-color: $grey-400;
		}
	}

	&__amount {
		display: flex;
		align-items: center;
		justify-content: center;
		width: var(--size);
		font-size: var(--font-size);
		text-align: center;
		pointer-events: none;
		border: none;
		border-right: 1px solid $grey-400;
		border-left: 1px solid $grey-400;

		&--borderless {
			border-right: none;
			border-left: none;
		}
	}
}
</style>
